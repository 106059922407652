<template>
  <div class="min-h-screen">
    <Transition mode="out-in">
      <div v-if="checkInSuccess">
        <CheckInSuccessPage
          :adult="reservationData.adult"
          :kids="reservationData.kids"
          :date="reservationData.date"
          :time="reservationData.time"
          :number-of-table="reservationData.numberOfTable"
          :restaurant-logo="checkInData.partnerLogo"
        />
      </div>
      <div v-else>
        <CheckinPage
          v-if="!canCheckIn"
          :checkin-time="checkInData.checkinTime"
          :booked-time="checkInData.bookedTime"
          :booking-id="`${checkInData.bookingId}`"
          :date="checkInData.bookedDate"
          :email="checkInData.email"
          :loading="checkInData.loading"
          :partner-logo="checkInData.partnerLogo"
          @on-can-check-in="onCanCheckIn"
          @on-submit-check-in="checkCanCheckIn"
          @on-click-qr="onClickQr"
        />
        <div v-else>
          <BookingConfirmation
            :type="'self-check-in'"
            version="mobile"
            booking-id=""
            access-token=""
            encrypted-id=""
            @on-check-in="toggleConfirmCheckInModal"
          />
          <ConfirmCheckInModal
            v-model:show-modal="showConfirmCheckInModal"
            :reservation-id="reservationData.bookingId"
            :email="reservationData.userEmail"
            :tnc-image="checkInData.tncImage"
            @on-success-check-in="onSuccessCheckIn"
            @on-failed-check-in="onFailedCheckIn"
          />
        </div>
      </div>
    </Transition>
    <FailedCheckInModal
      v-model:show-modal="showFailedCheckInModal"
      :checkin-time="checkInData.checkinTime"
      :booked-time="checkInData.bookedTime"
      :booked-date="checkInData.bookedDate"
      :error-type="checkInErrorType"
    />
    <QrScannerModal
      v-model:show-modal="showQrModal"
      @on-scan-success="onQrScanSuccess"
    />
    <FullPageLoader v-model:show-modal="showFullPageLoaderModal" />
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, ref, onMounted, computed } from "vue";
import { isNil, omitBy } from "lodash-es";
import { definePageMeta, reactive, useHandleError, useRoute } from "#imports";
import dayjs from "~/lib/dayjs";
import CheckinPage from "~/partial/self_checkin/CheckinPage.vue";
import { type ResponseData } from "~/api/book/getBookingDetail";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";
import { retryablePromise } from "~/helpers/retryablePromise";
import type { GrandSubItem } from "~/types/Booking";

const BookingConfirmation = defineAsyncComponent(
  () => import("~/partial/booking/BookingConfirmation.vue")
);

const ConfirmCheckInModal = defineAsyncComponent(
  () => import("~/partial/self_checkin/ConfirmCheckInModal.vue")
);
const CheckInSuccessPage = defineAsyncComponent(
  () => import("~/partial/self_checkin/CheckInSuccessPage.vue")
);
const FailedCheckInModal = defineAsyncComponent(
  () => import("~/partial/self_checkin/FailedCheckInModal.vue")
);
const QrScannerModal = defineAsyncComponent(
  () => import("~/partial/self_checkin/QrScannerModal.vue")
);
const FullPageLoader = defineAsyncComponent(
  () => import("~/components/FullPageLoader.vue")
);

const initialReservationData = {
  userEmail: "",
  adult: 0,
  kids: 0,
  date: "",
  time: "",
  bookingId: "",
  numberOfTable: "",
};
const route = useRoute();
const { params } = route;
const slug = computed(() => {
  return typeof params.slug === "string" ? params.slug : "";
});
const checkInUsingQr = ref(false);
const canCheckIn = ref(false);
const checkInSuccess = ref(false);
let reservationData = reactive({ ...initialReservationData });
const checkInData = reactive({
  partnerLogo: "",
  bookedTime: "",
  bookedDate: "",
  checkinTime: "",
  success: false,
  bookingId: "",
  email: "",
  message: "",
  loading: {
    getLogo: false,
    submit: false,
  },
  tncImage: "",
});
const checkInErrorType = computed<
  "too-early" | "late-check-in" | "invalid" | "not-found" | "arrived"
>(() => {
  if (
    ["Reservation not found", "Invalid email"].includes(checkInData.message)
  ) {
    return "not-found";
  }
  const invalids = [
    "Unable to process your check-in at this time",
    "Your Reservation Cancelled",
  ];
  if (
    !checkInData.checkinTime ||
    !checkInData.bookedDate ||
    invalids.includes(checkInData.message)
  ) {
    return "invalid";
  }
  const checkInDate = dayjs(
    `${checkInData.bookedDate} ${checkInData.bookedTime}`,
    "YYYY-MM-DD HH:ss"
  );
  if (dayjs().isAfter(checkInDate, "day")) {
    return "late-check-in";
  }
  return "too-early";
});
const showFailedCheckInModal = ref(false);
const showConfirmCheckInModal = ref(false);
const showQrModal = ref(false);
const showFullPageLoaderModal = ref(false);

function onCanCheckIn() {
  canCheckIn.value = true;
}

function onSuccessCheckIn() {
  checkInSuccess.value = true;
}

function onFailedCheckIn() {
  showConfirmCheckInModal.value = false;
  showFailedCheckInModal.value = true;
}

function onClickQr() {
  checkInUsingQr.value = false;
  showQrModal.value = !showQrModal.value;
}

async function onQrScanSuccess(bookingId: string) {
  showQrModal.value = false;
  checkInUsingQr.value = true;
  checkInData.bookingId = bookingId;
  showFullPageLoaderModal.value = true;
  await checkCanCheckIn({
    bookingId: checkInData.bookingId,
  });
  showFullPageLoaderModal.value = false;
  checkInUsingQr.value = false;
}

async function setReservationData() {
  const { useBookingConfirmationStore } = await retryablePromise(
    () => import("~/stores/bookingConfirmation")
  );
  const bookingConfirmationStore = useBookingConfirmationStore();
  reservationData.userEmail = bookingConfirmationStore.bookingData.email;
  reservationData.bookingId = bookingConfirmationStore.bookingData.id;
  reservationData.adult = bookingConfirmationStore.bookingData.adult;
  reservationData.kids = bookingConfirmationStore.bookingData.kids;
  reservationData.date = bookingConfirmationStore.bookingData.date;
  reservationData.time = bookingConfirmationStore.bookingData.startTime;
  reservationData.numberOfTable = bookingConfirmationStore.bookingData.table;

  const grandSubItem = {
    grandSubItem: [] as GrandSubItem[],
  };

  bookingConfirmationStore.bookingData.selectedSpecialMenus.forEach(
    (specialMenu) => {
      const grandSubItemObject = {
        name: specialMenu.name,
        quantity: specialMenu.quantity,
      };
      grandSubItem.grandSubItem.push(grandSubItemObject);
    }
  );

  bookingConfirmationStore.chargedItems.forEach((chargeItem) => {
    if (chargeItem.subItem && chargeItem.subItem.length === 0) {
      chargeItem.subItem.push(grandSubItem);
    }
  });
}

async function toggleConfirmCheckInModal() {
  await setReservationData();
  showConfirmCheckInModal.value = true;
}

async function getCheckInData() {
  checkInData.loading.getLogo = true;
  const { getCheckInData } = await retryablePromise(
    () => import("~/api/check_in/getCheckInData")
  );
  const { data, success } = await getCheckInData({ slug: slug.value });
  if (success && data) {
    checkInData.partnerLogo = data.logo.url;
    checkInData.loading.getLogo = false;
    checkInData.tncImage = data.tnc?.url || "";
  }
}

async function parseUrl() {
  const useQueryString = (
    await retryablePromise(() => import("~/composables/useQueryString"))
  ).default;
  const { getQueryString } = useQueryString();
  const { bookingId, email } = getQueryString();
  if (!email || !bookingId) {
    return;
  }
  checkInData.bookingId = `${bookingId}` || "";
  checkInData.email = email || "";
  showFullPageLoaderModal.value = true;
  await checkCanCheckIn({
    bookingId: checkInData.bookingId,
    email: checkInData.email,
  });
  showFullPageLoaderModal.value = false;
}

async function checkCanCheckIn({
  bookingId,
  email,
}: {
  bookingId: string;
  email?: string;
}) {
  const defaultErrorMessage = `${DEFAULT_ERROR_MESSAGE}, failed validate checkin data`;
  try {
    resetState();
    checkInData.loading.submit = true;
    const { checkCanCheckIn } = await retryablePromise(
      () => import("~/api/check_in/checkCanCheckIn")
    );
    const payload = {
      email: email && email.length ? email.trim() : undefined,
      reservationId: bookingId.trim(),
      slug: slug.value,
      skipEmail: checkInUsingQr.value ? true : undefined,
    };
    const { data, success, included, message } = await checkCanCheckIn(
      omitBy(payload, isNil) as typeof payload
    );
    checkInData.success = success;
    checkInData.message = message;
    if (checkInData.success) {
      if (data && included) {
        const reservationData = data as ResponseData;
        const { useBookingConfirmationStore } = await retryablePromise(
          () => import("~/stores/bookingConfirmation")
        );
        const bookingConfirmationStore = useBookingConfirmationStore();
        bookingConfirmationStore.parseReservationData({
          data: reservationData,
          included,
        });
        await setReservationData();
        if (reservationData.attributes?.statusAsSymbol === "arrived") {
          onSuccessCheckIn();
          return;
        }
        if (reservationData.attributes?.statusAsSymbol === "cancelled") {
          checkInData.message = "Your Reservation Cancelled";
          showFailedCheckInModal.value = true;
          return;
        }
        canCheckIn.value = true;
        return;
      }
    }
    if (data) {
      const checkInResponseData = data as Record<string, any>;
      checkInData.bookedTime = checkInResponseData.bookedTime || "";
      checkInData.checkinTime = checkInResponseData.startTime || "";
      checkInData.bookedDate = checkInResponseData.date || "";
      showFailedCheckInModal.value = true;
    } else {
      showFailedCheckInModal.value = true;
    }
  } catch (err) {
    useHandleError({
      err,
      defaultErrorMessage,
    });
    showFailedCheckInModal.value = true;
  } finally {
    checkInData.loading.submit = false;
  }
}

function resetState() {
  checkInData.message = "";
  checkInData.bookedTime = "";
  checkInData.checkinTime = "";
  checkInData.bookedDate = "";
  reservationData = initialReservationData;
}
onMounted(async () => {
  await parseUrl();
  await getCheckInData();
});

definePageMeta({
  layout: "hybrid",
});
</script>
