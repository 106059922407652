<template>
  <div class="container h-screen p-4">
    <div class="mb-4 flex items-center justify-center">
      <div>
        <HhImage v-bind="hhLogoImageProps" loading="lazy" alt="icon hh" />
      </div>
      <div v-if="partnerLogo">
        <HhImage v-bind="partnerLogoImageProps" alt="icon resto" />
      </div>
    </div>
    <div class="mb-4">
      <span class="text-[34px] font-bold leading-normal text-red-dark"
        >Self<br />Check-in</span
      >
    </div>
    <div>
      <div class="mb-4">
        <label class="mb-2">
          <BookingSectionTitle :title="t('bookingId')" />
        </label>
        <HhInput
          v-model="form.bookingId"
          type="number"
          class="rounded-lg border border-gray-tertiary px-4 py-2.5 text-lg"
        />
      </div>
      <div class="mb-4">
        <label class="mb-2">
          <BookingSectionTitle :title="t('emailUsed')" />
        </label>
        <HhInput
          v-model.trim="form.email"
          type="email"
          class="rounded-lg border border-gray-tertiary px-4 py-2.5 text-lg"
        />
      </div>
      <div class="mb-4">
        <HhButton
          block
          :disabled="isButtonReviewDisabled || loading.submit"
          class="enabled:bg-gradient-red rounded-lg py-2.5 !text-base font-bold text-white disabled:bg-gray-tertiary"
          :loading="loading.submit"
          @click="submitCheckIn"
        >
          {{ t("reviewInformation") }}
        </HhButton>
      </div>
      <div class="mb-4 text-center">
        <span class="text-base text-gray-secondary">{{ t("or") }}</span>
      </div>
      <div class="mb-4">
        <HhButton
          block
          class="flex items-center justify-center rounded-lg border border-red-dark bg-white py-2.5 !text-base font-bold !text-red-dark"
          @click="emits('on-click-qr')"
        >
          <IconQRCode class="mr-2" /> {{ t("checkInWithQr") }}
        </HhButton>
      </div>
    </div>

    <div
      class="absolute bottom-[10%] left-0 right-0 mx-auto w-[100px] lg:bottom-[2%]"
    >
      <HhDropdown placement="top">
        <template #trigger>
          <button
            class="flex h-[35px] w-[100px] items-center justify-between gap-[3px] rounded bg-white text-[13px] leading-none text-gray-shadow outline-none hover:bg-gray-200 focus:shadow-[0_0_0_2px] focus:shadow-black"
          >
            <svg
              class="flex-shrink-0"
              width="24"
              height="24"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.35 16.92 6ZM10 2.04C10.83 3.24 11.48 4.57 11.91 6H8.09C8.52 4.57 9.17 3.24 10 2.04ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12H2.26ZM3.08 14H6.03C6.35 15.25 6.81 16.45 7.41 17.56C5.57 16.93 4.04 15.66 3.08 14ZM6.03 6H3.08C4.04 4.34 5.57 3.07 7.41 2.44C6.81 3.55 6.35 4.75 6.03 6ZM10 17.96C9.17 16.76 8.52 15.43 8.09 14H11.91C11.48 15.43 10.83 16.76 10 17.96ZM12.34 12H7.66C7.57 11.34 7.5 10.68 7.5 10C7.5 9.32 7.57 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12H14.36Z"
                fill="#A6A6A6"
              />
            </svg>
            <span>{{ langFullName(selectedLang) }}</span>
            <IcChevronDown class="flex-shrink-0 text-lg text-gray-shadow" />
          </button>
        </template>
        <template #content>
          <div>
            <div
              v-for="(lang, index) in languageOption"
              :key="index"
              class="flex h-[30px] cursor-pointer select-none items-center justify-center px-[35px] leading-none text-gray-shadow hover:bg-gray-200 data-[disabled]:pointer-events-none data-[highlighted]:outline-none"
              @click="selectedLang = lang"
            >
              {{ langFullName(lang) }}
            </div>
          </div>
        </template>
      </HhDropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import {
  reactive,
  defineAsyncComponent,
  computed,
  watch,
  ref,
  useRoute,
} from "#imports";
import HhXLogo from "~/assets/image/hh-x.png";
import IconQRCode from "~icons/ic/baseline-qr-code-scanner";
import alert from "~/lib/alert";
import IcChevronDown from "~icons/ic/round-keyboard-arrow-down";

type Props = {
  checkinTime: string;
  date: string;
  partnerLogo: string;
  bookedTime: string;
  bookingId: string;
  email: string;
  loading: {
    getLogo: boolean;
    submit: boolean;
  };
};
type Emits = {
  (
    event: "on-submit-check-in",
    value: { bookingId: string; email: string }
  ): void;
  (event: "on-click-qr"): void;
};

const partnerLogoImageProps = computed(() => {
  return {
    src: props.partnerLogo,
    width: Math.ceil(83 * 1.2),
    height: Math.ceil(27 * 1.2),
    useMutator: true,
  };
});
const hhLogoImageProps = computed(() => {
  return {
    src: HhXLogo,
    width: Math.ceil(83 * 1.4),
    height: Math.ceil(27 * 1.4),
    useMutator: false,
  };
});

const BookingSectionTitle = defineAsyncComponent(
  () => import("~/components/booking/BookingSectionTitle.vue")
);
const HhImage = defineAsyncComponent(() => import("~/components/HhImage.vue"));
const HhInput = defineAsyncComponent(() => import("~/components/HhInput.vue"));
const HhButton = defineAsyncComponent(
  () => import("~/components/HhButton.vue")
);
const HhDropdown = defineAsyncComponent(
  () => import("~/components/HhDropdown.vue")
);

const { params } = useRoute();
const selectedLang = ref(
  Array.isArray(params.lang) ? params.lang[0] : params.lang
);
const languageOption = computed(() => {
  return ["en", "th"].filter((lang) => lang !== selectedLang.value);
});
const { t } = useI18n();
const props = defineProps<Props>();
const emits = defineEmits<Emits>();
const form = reactive({
  bookingId: "",
  email: "",
});

const isButtonReviewDisabled = computed(() => {
  if (form.bookingId !== "" && form.email !== "") {
    return false;
  }
  return true;
});

function langFullName(lang: string) {
  return lang === "en" ? "English" : "Thai";
}

function submitCheckIn() {
  if (!form.bookingId || !form.email) {
    alert.error("Please provide Booking Id and Email");
    return;
  }
  emits("on-submit-check-in", { bookingId: form.bookingId, email: form.email });
}

function navigateNewPage(newLang: string) {
  const route = useRoute();
  window.location.href = `/${newLang}/checkin/${route.params.slug}`;
}

watch(
  [() => props.bookingId, () => props.email],
  ([bookingIdVal, emailVal]) => {
    form.bookingId = bookingIdVal;
    form.email = emailVal;
  },
  { immediate: true }
);

watch(selectedLang, (newLang) => {
  navigateNewPage(newLang);
});
</script>

<i18n lang="json">
{
  "en": {
    "bookingId": "Booking ID",
    "emailUsed": "Email used for this booking",
    "reviewInformation": "Review Your Information",
    "or": "or",
    "checkInWithQr": "Check-in with QR Code"
  },
  "th": {
    "bookingId": "หมายเลขการจอง",
    "emailUsed": "อีเมลที่ใช้สำหรับการจอง",
    "reviewInformation": "ตรวจสอบข้อมูลของคุณ",
    "or": "หรือ",
    "checkInWithQr": "เช็คอินด้วย QR Code"
  }
}
</i18n>
